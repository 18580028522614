import React, { Component, useEffect, useState } from 'react';
import axios from '../../axios'
import APIs from '../../APIs/APIs';
import Loader from './Loader';


const Product = () => {
	const [data, setData] = useState()
	const [loading, setLoading] = useState(false)
	let IMG_URL = "https://stg.rep.start-tech.ae/"
	const fetchPopularCommunities = () => {
		setLoading(true)
		axios.get(APIs.PopularCommunities).then((res) => {
			setData(res.data.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
		});
	}

	useEffect(() => {

		fetchPopularCommunities()
	}, [])

	let publicUrl = process.env.PUBLIC_URL + '/'

	return <div className="product-area pd-top-118 pd-bottom-90 go-top">
		<div className="container">
			<div className="section-title text-center">
				<h2>Popular Communities in Dubai</h2>
				<h6>City Of Future</h6>
			</div>
			<div className="row justify-content-center">
				{
					loading ? <Loader loading={loading} color="#0d1741" /> :
						data && data.map((item) => {
							return (
								<div className="col-lg-4 col-md-6">
									<div className="single-product-wrap">
										<div className="thumb">
											<img src={IMG_URL + item?.image} alt="img" />
										</div>
										<div className="product-wrap-details">
											<div className="product-meta">
												<span className="dubaiMarin">{item?.name}</span>
											</div>
										</div>
									</div>
								</div>
							)
						})
				}



			</div>
		</div>
	</div>

}


export default Product