import React, { Component, useState } from 'react';
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import MapContainer from './MapContainer';
import PhoneInput from 'react-phone-input-2'



const ContactForm = ({ data }) => {
  const [email, setEmail] = useState("")
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [phone, setPhone] = useState("971")
  const [message, setMessage] = useState("")
  const [comment, setComment] = useState("")
  const [loading, setLoading] = useState(false)
  const [fn_err, setFnErr] = useState("")
  const [ln_err, setLnErr] = useState("")
  const [phone_err, setPhoneErr] = useState("")
  const [err_message, setErr_message] = useState("")
  let publicUrl = process.env.PUBLIC_URL + '/'


  const clearInput = () => {
    setEmail("")
    setFirstName("")
    setLastName("")
    setPhone("971")
    setMessage("")
  }

  const checkInputs = () => {
    setFnErr("")
    setLnErr("")
    setPhoneErr("")

    if (first_name.length == 0) {
      setFnErr("The first name field is required.")
    }
    if (last_name.length == 0) {
      setLnErr("The last name field is required.")
    }
    if (phone.length >= 3 && phone.length <= 12) {
      setPhoneErr("The phone number field is required.")
    }

    if (first_name.length > 0 && last_name.length > 0 && phone.length > 9) {
      return true
    }
    else {
      return false
    }
  }

  const sendMessage = (e) => {
    e.preventDefault()
    setErr_message("")
    setComment("")
    if (checkInputs()) {

      setLoading(true)
      axios.post(APIs.contactUs_SendMessage, { email, first_name, last_name, phone: `+${phone}`, message }).then((res) => {
        setComment(res.data.message)
        clearInput()
        setLoading(false)
        setPhoneErr("")
      }).catch(error => {
        // clearInput()
        setErr_message(error?.response?.data?.message)
        setLoading(false)
      });
    }
  }



  return <div style={{ marginTop: "-7.5rem" }} className="contact-page-area pd-top-120">
    <div className="container">
      <div className="col-12 btn btn-base hover-none">Selling the dream in Dubai</div>
      <div className="row">
        <div className="col-xl-6 col-lg-7 mb-5 mb-lg-0">
          <div className="contact-details-inner mng-box-shadow">
            <h4>Contact</h4>
            <div dangerouslySetInnerHTML={{ __html: data?.contactusexpression_en }} />
            <div className="row">
              <div className="col-md-6">
                <div className="contact-single-list">
                  <h5>Address</h5>
                  <div className='d-flex mb-3 '>
                    <i className="fas fa-map-marker-alt mt-1 mr-2" />
                    <div className='contact__' dangerouslySetInnerHTML={{ __html: data?.addres }} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-single-list">
                  <h5>Phone</h5>

                  <div dangerouslySetInnerHTML={{ __html: data?.contactinformation }} />
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="contact-single-date">
                  <div dangerouslySetInnerHTML={{ __html: data?.workingtimes_en }} />

                </div>
              </div>
              <div className="col-md-6 align-self-center text-md-right">
                <ul className="social-area style-3">
                  <li><a href={data?.links.facebook}><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                  <li><a href={data?.links.twitter}><i className="fab fa-twitter" aria-hidden="true" /></a></li>
                  <li><a href={data?.links.instagram}><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                  <li><a href={data?.links.youTube}><i className="fab fa-youtube" aria-hidden="true" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2rem" }} className="col-xl-6 col-lg-5">
          <form onSubmit={sendMessage}>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <label className="single-input-inner style-bg-border">
                  <input required type="text" placeholder="First Name" value={first_name} onChange={(e) => setFirstName(e.target.value)} />
                  <p className='val_error'> {first_name.length > 0 ? "" : fn_err}</p>

                </label>
              </div>
              <div className="col-xl-6 col-lg-6">
                <label className="single-input-inner style-bg-border">
                  <input required type="text" placeholder="Last Name" value={last_name} onChange={(e) => setLastName(e.target.value)} />

                  <p className='val_error'> {last_name.length > 0 ? "" : ln_err}</p>
                </label>
              </div>
              <div className="col-md-12">
                <label className="single-input-inner style-bg-border">
                  <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </label>
              </div>
              <div className="col-md-12">
                <label className="lable_number style-bg-border">
                  <PhoneInput
                    className=" style-bg-border nmber_f"
                    country={'ae'}
                    onChange={(e) => setPhone(e)}
                    value={phone}

                  />
                </label>
                <p className='val_error'>{phone.length >= 3 && phone.length >= 12 ? "" : phone_err}</p>
              </div>
              <div className="col-12 mt-4">
                <label className="single-input-inner style-bg-border">
                  <textarea placeholder="Message" defaultValue={message} onChange={(e) => setMessage(e.target.value)} />
                </label>
              </div>
              <div className="col-12 mb-4">
                <p className='succus mb-2'>{comment}</p>
                <p className='val_error mb-2'>{err_message}</p>
                <button className="btn btn-base mt-2" disabled={loading} onClick={sendMessage}>Send Email</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="contact-page-map mg-top-100">
      <div className='map map_h6'>

        <MapContainer location={data?.location} zoom={12} height="600px" width="100%" />
      </div>
      {/* <iframe src={`https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d465540.3416004624!2d${data?.location?.lng}!3d${data?.location?.lat}!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbg!4v1690030982071!5m2!1sen!2sbg`} /> */}
    </div>
  </div>


}

export default ContactForm