import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const Banner = ({ data }) => {


	let bannerImage = "https://i0.wp.com/remaxnetworkhome.com/wp-content/uploads/2022/09/23-min.webp?fit=1024%2C575&ssl=1"

	return <div className="banner-area banner-area-1 banner-area-bg" style={{ background: `url(${bannerImage})` }}>
		<div className="container">
			<div className="banner-area-inner">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<div className="banner-inner text-center">
							<h1 style={{ color: "#FFF" }}>{data?.name}</h1>
							<div className="line" />
							<h2>Bring The World Together</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


}

export default Banner

