import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OffPlanCard from '../offPlanCard';

class Agent extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="agent-area text-center pd-top-118 pd-bottom-90">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <OffPlanCard />
              </div>
              <div className="col-lg-4 col-md-6">
                <OffPlanCard />
              </div>
              <div className="col-lg-4 col-md-6">
                <OffPlanCard />
              </div>
            </div>
          </div>
        </div>


        }
}

export default Agent