
const id = `d926ccca-22e7-11ee-b5bd-0050563f7167`
const APIs = {
    //#region admin-login
    MainInformation: `/${id}`,
    PopularCommunities: `/${id}/popular-communities`,
    premiumProperties: `/${id}/premium-properties`,
    FeaturedDevelopers: `/${id}/featured-developers`,
    LatestUpdates: `/${id}/latest-updates`,
    Subscribe: `/${id}/subscribe`,
    SendMessage: `/${id}/send-message`,
    Blogs: `/${id}/blogs`,
    About: `/${id}/about`,
    Team: `/${id}/team`,
    Careers: `/${id}/careers`,
    privacyPolicy: `/${id}/privacy-policy`,
    contactUs: `/${id}/contact-us`,
    contactUs_SendMessage: `/${id}/contact-us/send-message`,
    SellPage: `/${id}/sell`,
    Properties: `/${id}/properties`,
    propertyDetails: `/${id}/properties/property-details`,
    listProperty: `/${id}/list-property`,
    featuredDeveloper: `/${id}/featured-developer`,
    filterationData: `/${id}/filteration_data`,



};
export default APIs;
