import React, { useState } from 'react'
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import PhoneInput from 'react-phone-input-2'

const AboutMe = ({ data, id }) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile_number, setMobile_number] = useState("971")
    const [comment, setComment] = useState("")
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState("")
    const [err_result, setErrResult] = useState("")

    const [nameErr, setNameErr] = useState("")
    const [phoneErr, setPhoneErr] = useState("")

    const checkInput = () => {
        setNameErr('')
        setPhoneErr('')
        setResult("")
        if (name.length == 0) {
            setNameErr("The name field is required.")

        }
        if (mobile_number.length >= 3 && mobile_number.length <= 11) {
            setPhoneErr("Please enter valid mobile number.")

        }
        if (name.length > 0 && mobile_number.length > 9) {
            return true
        }
        else {
            return false
        }

    }

    const addCommint = (e) => {
        e.preventDefault();
        if (checkInput()) {

            setLoading(true)
            axios.post(`${APIs.propertyDetails}/${id}/send-message`, {
                email, name, comment, mobile_number: `+${mobile_number}`
            }).then((res) => {
                setResult(res.data.message)
                setLoading(false)
                setComment("")
                setName("")
                setEmail("")
                setMobile_number("971")
            }).catch(error => {
                setLoading(false)
                setErrResult(error?.response?.data?.message)
            });
        }
    }

    return (
        <div className="widget widget-author text-center">
            <h4 className="widget-title">About Me</h4>
            <div className="thumb">
                <img src={data?.user?.image} alt="img" />
            </div>
            <div className="details">
                <h5>{data?.user?.name}</h5>
                <p>{data?.user?.role}</p>
                <div className="col-12 mb-4">
                    <button className="btn btn-base radius-btn">Schedule a showing?</button>
                </div>
                <div className="col-md-12">
                    <label className="single-input-inner style-bg-border">
                        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        {nameErr && <p className='about_me_check val_error'>{nameErr}</p>}
                    </label>
                </div>
                <div className="col-md-12">
                    <label className="single-input-inner style-bg-border">
                        <input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />

                    </label>
                </div>
                <div className="col-md-12">
                    <label className="lable_number style-bg-border">
                        <PhoneInput
                            className=" style-bg-border nmber_f"
                            country={'ae'}
                            value={mobile_number}
                            onChange={(e) => setMobile_number(e)}
                            placeholder='Phone'
                        />
                    </label>
                    {phoneErr && <p className=' val_error'> {phoneErr}</p>}

                </div>
                <div className="col-12 mt-3">
                    <label className="single-input-inner style-bg-border">
                        <textarea placeholder="Message" onChange={(e) => setComment(e.target.value)} value={comment} />
                    </label>
                </div>
                {result && <p className='succus'> {result}</p>}
                {err_result && <p className='about_me_check val_error ml-2 mb-4'> {err_result}</p>}
                <div className="col-12 mb-4">
                    <button className="btn btn-base radius-btn" disabled={loading} onClick={addCommint}>Send Email</button>
                </div>
                <div className='row'>
                    <div className="col-6 mb-4">
                        <a href={`tel:${data?.user?.phone}`} style={{ width: "100%" }} className="btn btn-base radius-btn"><i className="fas fa-phone"></i></a>
                    </div>
                    <div className="col-6 mb-4">
                        <a href={`https://api.whatsapp.com/send?phone=${data?.user?.whatsapp}`} style={{ width: "100%" }} className="btn btn-base radius-btn text-white"><i class="fab fa-whatsapp"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutMe
