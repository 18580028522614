import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import Loader from './Loader';

const ProductV2 = () => {

	const [data, setData] = useState()
	const [loading, setLoading] = useState(false)

	const fetchFeaturedDevelopers = () => {
		setLoading(true)
		axios.get(APIs.FeaturedDevelopers).then((res) => {
			setData(res.data.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
		});
	}

	useEffect(() => {

		fetchFeaturedDevelopers()
	}, [])


	let IMG_URL = "https://stg.rep.start-tech.ae/"
	let publicUrl = process.env.PUBLIC_URL + '/'

	return <div style={{ marginTop: "-5rem" }} className="product-area pd-top-118 pd-bottom-90 go-top">
		<div className="container">
			<div className="section-title text-center">
				<h2>Our Featured Developers</h2>
			</div>
			<div className="row">
				{
					loading ? <Loader loading={loading} color="#0d1741" /> :
						data?.map((item) => {
							return (
								<div className="col-lg-4 col-md-6">
									<Link to={`/featureddevelopers/${item?.id}`}>
										<div className="single-category-product-wrap">
											<div className="thumb">
												<img src={IMG_URL + item?.image} alt="img" />
											</div>
											<div className="single-category-product-details">
												<h4><Link to={`/featureddevelopers/${item?.id}`}>{item?.name}</Link></h4>
											</div>
										</div>
									</Link>
								</div>
							)
						})

				}
			</div>
		</div>
	</div>


}

export default ProductV2