import React, { Component } from 'react';
import TeamMemCard from '../teamMemCard';

const Team = ({ data }) => {

  return <div className="agent-area text-center pd-top-118 pd-bottom-90">
    <div className="container">
      <div className="row">
        {
          data?.map((item, idx) => {
            return (
              <div className="col-lg-4 col-md-6" key={idx}>
                <TeamMemCard id={item?.id} memberName={item?.name} role={item?.role} memberImage={item?.image} />
              </div>

            )
          })
        }

      </div>
    </div>
  </div>


}

export default Team