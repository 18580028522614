import React, { Component } from 'react';
const About = ({ data }) => {
  let publicUrl = process.env.PUBLIC_URL + '/'

  return <div className="about-area bg-gray pd-top-120 pd-bottom-120">
    <div className="container">
      <div className="single-about-inner about-line-right bg-white">
        <div className="row no-gutter">
          <div className="col-lg-4 order-lg-12">
            <div className="thumb" style={{ background: 'url(https://i0.wp.com/remaxnetworkhome.com/wp-content/uploads/2022/06/photo_٢٠٢٢-٠٦-١٧_٢٠-١٥-٠٨.jpg?fit=1280%2C853&ssl=1)' }} />
          </div>
          <div className="col-lg-8 order-lg-1">
            <div className="details">
              <div className="section-title mb-4">
                <h2>{data?.name}</h2>
                <div dangerouslySetInnerHTML={{ __html: data?.description_en }} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

}

export default About