import React, { Component, useEffect, useState } from 'react';
import SingleProperty from "./singleProperty"
import APIs from '../../APIs/APIs';
import axios from '../../axios'
import Loader from './Loader';

const Property = () => {
	const [type, setType] = useState(1)
	const [data, setData] = useState()
	const [loading, setLoading] = useState(false)


	const fetchpremiumProperties = () => {
		setLoading(true)
		axios.get(`${APIs.premiumProperties}/${type}`).then((res) => {
			setData(res.data.data)
			setLoading(false)
		}).catch(error => {
			setLoading(false)
		});
	}

	useEffect(() => {

		fetchpremiumProperties()
	}, [type])



	return <div style={{ marginTop: "-5rem" }} className="propartes-area pd-top-118 pd-bottom-90 ">
		<div className="container">
			<div className="section-title text-center">
				<h2>Premium Properties </h2>
			</div>
			<div className="mgd-tab-inner text-center">
				<ul className="nav nav-tabs" id="myTab" role="tablist">
					<li className="nav-item">

						<button className={`btn nav-link ${type == 1 ? "active" : ""} `} onClick={() => setType(1)}>Rent</button>
					</li>
					<li className="nav-item">
						<button className={`btn nav-link ${type == 0 ? "active" : ""} `} onClick={() => setType(0)} >Sell</button>
					</li>
				</ul>
			</div>
			<div className="tab-content go-top" id="myTabContent">
				<div className="tab-pane fade show active" >
					<div className="row go-top">

						{loading ? <Loader loading={loading} color="#0d1741" /> :
							data?.map((item, idx) => {
								return (
									<div className="col-lg-4 col-md-6" key={idx}>
										<SingleProperty data={item} />
									</div>
								)
							})
						}
					</div>
				</div>

			</div>
		</div>
	</div>


}

export default Property