import axios from "axios";

const instancs = axios.create({
    baseURL: "https://stg.rep.start-tech.ae/api",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});


export default instancs;
