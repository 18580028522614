import React, { Component } from 'react';
import { Link } from 'react-router-dom';


const BannerV2 = ({ bannerImage, our_m }) => {
	let publicUrl = process.env.PUBLIC_URL + '/'
	let bannerImage1 = bannerImage || publicUrl + 'assets/img/banner/2.png';


	return <div className="banner-area banner-area-2 banner-area-bg" style={{ background: `url(${bannerImage1})` }}>
		<div className="container">
			<div className="banner-area-inner">
				<div className="row justify-content-center">
					<div className="col-lg-8">
						<div className="banner-inner text-center">
							<h2>MISSION STATEMENT</h2>
							<div className="line" />
							<div dangerouslySetInnerHTML={{ __html: our_m?.missionstatement_en }} />
							<Link to="contact"><button className="btn btn-base" style={{ marginTop: "50px" }}>Contact Us</button></Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>



}

export default BannerV2