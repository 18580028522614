import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Team from './section-components/team';
import Footer from './global-components/footer';
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';

const AboutPage = () => {

    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const [f_data, setFdata] = useState(true)
    const fetchTeams = () => {
        setLoading(true)
        axios.get(APIs.Team).then((res) => {
            setDate(res.data.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {
        if (f_data) {

            fetchTeams()
            window.scrollTo(0, 0)
            setFdata(false)
        }

    }, [])

    return (
        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :
            <div>

                <PageHeader headertitle="Meet Our Team" headerImage="http://remaxnetworkhome.com/wp-content/uploads/2022/06/Depositphotos_25685857_xl-2015-copy-scaled-e1633104480116.jpg" />
                <Team data={data} />
            </div>)
}

export default AboutPage

