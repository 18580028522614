import React, { useEffect, useState } from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import About from './section-components/about';
import Counter from './section-components/counter';
import Footer from './global-components/footer';
import BannerV2 from './section-components/banner-v2';
import DetailsContainer from './detailsContainer';
import APIs from '../APIs/APIs';
import axios from '../axios'
import Loader from './section-components/Loader';

const AboutPage = () => {

    const [data, setDate] = useState()
    const [loading, setLoading] = useState(false)
    const fetchAbout = () => {
        setLoading(true)
        axios.get(APIs.About).then((res) => {
            setDate(res.data.message)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        });
    }

    useEffect(() => {

        fetchAbout()

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let IMG_URL = "https://stg.rep.start-tech.ae/"
    return (

        loading ? <Loader loading={loading} color="#fff" height="100" bg="blue" /> :

            <div>
                <PageHeader headertitle="About Us" headerImage="http://remaxnetworkhome.com/wp-content/uploads/2021/11/shutterstock_435578038.png" />
                <About data={data} />
                <BannerV2 our_m={data} bannerImage="http://remaxnetworkhome.com/wp-content/uploads/2021/11/Group-1871.png" />
                <DetailsContainer textHeader={data?.name} text={data?.ourstory_en} secoundComponent={
                    <iframe style={{ marginTop: "10%" }} width="420" height="315" src={IMG_URL + data?.company_video} allow="autoplay" ></iframe>

                } />
                <Counter cout={data} />
                <DetailsContainer text={data?.ourvalues_en} secoundComponentHeader="Our Values" secoundComponent={<img style={{ borderRadius: "20px" }} width="500px" src={IMG_URL + data?.our_values_image}></img>} />
            </div>

    )
}

export default AboutPage

