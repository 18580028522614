import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const Counter = ({ cout }) => {

  return <div className="couner-area bg-overlay pd-top-118 pd-bottom-120" >
    <div className="container">
      <div className="section-title text-center">
        <h2 style={{ color: "#fff" }}>{cout?.name}</h2>
      </div>
      <div className="row">
        <div className="col-sm-3">
          <div className="single-counter-inner text-center">
            <h2 className="counter">{cout?.agentsworldwide}</h2>
            <p>AGENTS WORLDWIDE</p>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="single-counter-inner text-center">
            <h2 className="counter">{cout?.officesworldwide}</h2>
            <p>OFFICES WORLDWIDE</p>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="single-counter-inner text-center">
            <h2 className="counter">{cout?.countriesandterritories}</h2>
            <p>COUNTRIES & TERRITORIES</p>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="single-counter-inner text-center">
            <h2 className="counter">{cout?.yearsofexperience}</h2>
            <p>YEARS OF EXPERIENCE</p>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Counter